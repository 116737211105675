export const columnsDataDevelopment = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "TECH",
    accessor: "tech",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
];

export const columnsDataCheck = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
  {
    Header: "QUANTITY",
    accessor: "quantity",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
];

export const columnsDataColumns = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
  {
    Header: "QUANTITY",
    accessor: "quantity",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
];

export const columnsDataComplex = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "STATUS",
    accessor: "status",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
];


export const columnsDataComplextwo = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "QID",
    accessor: "qid",
  },
  {
    Header: "EMAIL",
    accessor: "contact.email",
  },

  {
    Header: "PHONE NUMBER",
    accessor: "contact.mobilePhone",
  },

  {
    Header: "DATE OF BIRTH",
    accessor: "dateofbirth",
  },

  {
    Header: "NATIONALITY",
    accessor: "nationality",
  },

  {
    Header: "GENDER",
    accessor: "gender",
  },

  {
    Header: "ACTION",
    accessor: "id",
  }

];
