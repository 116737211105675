import axios from 'axios';
import ClientsTableData from "./components/ClientsTableData";
import { useEffect, useState } from "react";
import { columnsDataComplextwo } from "./variables/columnsData";
import { useAuth } from 'context/auth-context';

const Tables = () => {
  const [clients, setClients] = useState([]);
  const SERVER_URL = process.env.REACT_APP_API_BASE_URL;
  const { user } = useAuth();

  //get list of all clients
  useEffect(() => {
    axios
      .get(`${SERVER_URL}/api/clients/`, {
        headers: {
          'Authorization': `Bearer ${user.token}`
        }
      })
      .then((res) => setClients(res.data.data))
      .catch((err) => {
        console.log(err.message);
      });
  }, [SERVER_URL, user.token]);

  return (
    <div>
      <div className="mt-5 grid h-full grid-cols-1 gap-5 md:grid-cols-1">
        <ClientsTableData
          columnsData={columnsDataComplextwo}
          tableData={clients}
        />
      </div>
    </div>
  );
};

export default Tables;
