import Card from "components/card";
import React from "react";
import Scenepreview from "./Camerathirdpreview";

const General = () => {
  return (
    <Card extra={"w-full  p-3 "}>
      {/* Header */}
      <div className="mt-2 mb-8 w-full ">
        <h4 className="px-2 flex justify-center items-center text-xl font-bold text-navy-700 dark:text-white">
          VR Headset
        </h4>                 

      </div>
      <Scenepreview />

    </Card>
  );
};

export default General;