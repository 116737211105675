import React, { useState, useEffect } from "react";
import Card from "components/card";
import Switch from "components/switch";
import axios from "axios";
import TextField from "components/fields/TextField";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaSpinner } from "react-icons/fa";
import * as R from "./ToggleFunctions";
import { useAuth } from "context/auth-context";

function ToggleHistory({ sessionId }) {
  const SERVER_URL = process.env.REACT_APP_API_BASE_URL;
  const [seatSelection, setSeatSelection] = useState("");
  const [disableSaveButton, setDisableSaveButton] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [comment, setComment] = useState("");
  const [toggles, setToggles] = useState([]);
  const [eventId, setEventId] = useState('');
  const [showSeats, setShowSeats] = useState(false);
  let simulationId = '658830d664542b6731582c1d';
  const { user } = useAuth();

  useEffect(() => {
    // Fetch toggles data from the backend
    const fetchToggles = async () => {
      try {
        const response = await axios.get(`${SERVER_URL}/api/toggle/${simulationId}`, {
          headers: {
            'Authorization': `Bearer ${user.token}`
          }
        });
        setToggles(response.data.data || []);
      } catch (error) {
        console.error("Error fetching toggles:", error);
      }
    };

    fetchToggles();
  }, [SERVER_URL, simulationId, user.token]);

  const handleCommentsField = (e) => {
    setComment(e.target.value);
  };

  const saveEventToDatabase = async (toggleId, eventStatus) => {
    const eventData = {
      toggleId: toggleId,
      sessionId: sessionId,
      status: eventStatus,
    };

    try {
      const res = await axios.post(`${SERVER_URL}/api/eventhistory/create/${sessionId}`,
      eventData, 
      {
        headers: {
          'Authorization': `Bearer ${user.token}`
        }
      });
      setEventId(res.data.data.id);
      toast("🚀  Event saved successfully!");
    } catch (error) {
      console.error("Error saving event history:", error);
    }
  };

  const handleSwitchToggle = async (toggleId, isChecked, toggleName) => {
    saveEventToDatabase(toggleId, isChecked ? "ON" : "OFF");
    if (toggleName === "Select Seat" && isChecked) {
      setShowSeats(true);
    } else if (toggleName === "Select Seat" && !isChecked){
      setShowSeats(false);
    }
    //add the vr integration here
    switch (toggleName) {
      case "Flight Introduction":
        if (isChecked) {
          await R.turnEngineOn(SERVER_URL, user);
        } else {
          await R.turnEngineOff(SERVER_URL, user);
        }
        break;
      case "Flight Instructions":
        if (isChecked) {
          await R.instructionOn(SERVER_URL, user);
        } else {
          await R.instructionOff(SERVER_URL, user);
        }
        break;
      case "Start Airplane":
        if (isChecked) {
          await R.startPlane(SERVER_URL, user);
        } else {
          await R.stopPlane(SERVER_URL, user);
        }
        break;
      case "Show Passenger":
        if (isChecked) {
          await R.showPeople(SERVER_URL, user);
        } else {
          await R.hidePeople(SERVER_URL, user);
        }
        break;
      case "Window Lights":
        if (isChecked) {
          await R.windowLightOn(SERVER_URL, user);
        } else {
          await R.windowLightOff(SERVER_URL, user);
        }
        break;
      case "Ceiling Lights":
        if (isChecked) {
          await R.ceilingLightOn(SERVER_URL, user);
        } else {
          await R.ceilingLightOff(SERVER_URL, user);
        }
        break;
      // Add more cases for other toggle names if needed
      default:
        break;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setDisableSaveButton(true);
    setIsLoading(true);

    // Send event when form is submitted
    // saveEventToDatabase("formSubmission", "submitted");

    const body = {
      comment: comment,
    };

    try {
      await axios.put(`${SERVER_URL}/api/eventhistory/update/${eventId}`, body, {
        headers: {
          'Authorization': `Bearer ${user.token}`
        }
      });
      setComment('');
      event.target.reset();
      toast("🚀  Event saved successfully!");
      setIsLoading(false);
      setDisableSaveButton(false);
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  const onSeatSelectionChange = async (event) => {
    event.preventDefault();
    setSeatSelection(event.target.value);
    //console.log(event.target.value);

    const body = {
      dataType: "seatselection",
      seatselection: event.target.value,
    };

    try {
      await axios.put(`${SERVER_URL}/api/vrdata`, body, {
        headers: {
          'Authorization': `Bearer ${user.token}`
        }
      });
      //console.log(res);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className="grid w-full grid-cols-6 gap-5">
      <Card extra={"p-3 col-span-3 flex flex-cols"}>
        <div className="relative mb-3 flex items-center justify-between pt-1">
          <h4 className="text-xl font-bold text-navy-700 dark:text-white">
            Toggle
          </h4>
        </div>
        <div className="flex flex-col">
          {/* Switch components */}

          {/* Assuming handleSwitchToggle receives the switch name as argument */}
          {toggles.map((toggle) => (
            <div key={toggle.id} className="mt-3 flex items-center gap-3">
              <Switch
                handleswitch={(isChecked) =>
                  handleSwitchToggle(toggle.id, isChecked, toggle.name)
                }
              />
              <label
                htmlFor={`checkbox-${toggle.id}`}
                className="text-base font-medium text-navy-700 dark:text-white"
              >
                {toggle.name}
              </label>
            </div>
          ))}

          {/* Other switches with similar pattern */}

          {/* Seat selection */}
          {showSeats ? (
            <div className="mt-5 flex items-center gap-3">
              <select
                value={seatSelection}
                onChange={onSeatSelectionChange}
                className="flex h-7 w-14 items-center rounded-lg border bg-white/0 p-1 text-sm outline-none"
              >
                {/* Seat options */}
                <option value="1d" id="1">
                  1D
                </option>
                <option value="1a" id="2">
                  1A
                </option>
                <option value="6d" id="3">
                  6D
                </option>
                <option value="6a" id="4">
                  6A
                </option>
                <option value="7d" id="5">
                  7D
                </option>
                <option value="7a" id="6">
                  7A
                </option>
                <option value="11j" id="7">
                  11J
                </option>
                <option value="11a" id="8">
                  11A
                </option>
                <option value="17j" id="9">
                  17J
                </option>
                <option value="17a" id="10">
                  17A
                </option>
                <option value="23j" id="11">
                  23J
                </option>
                <option value="23a" id="12">
                  23A
                </option>
                <option value="33j" id="13">
                  33J
                </option>
                <option value="33a" id="14">
                  33A
                </option>
              </select>
              <label
                htmlFor="seatselection"
                className="text-base font-medium text-navy-700 dark:text-white"
              >
                Select Seat
              </label>
            </div>
          ) : null}
        </div>
      </Card>
      <Card className="col-span-3 flex flex-col">
        <form onSubmit={handleSubmit}>
          <h4 className="px-2 text-xl font-bold text-navy-700 dark:text-white">
            Comments
          </h4>
          <TextField
            id="comment"
            placeholder="Observations"
            rows="7"
            onChange={handleCommentsField}
            value={comment}
          />
          <div className="mt-4 ml-2 flex gap-2">
            <button
              type="submit"
              className={`${
                disableSaveButton ? "pointer-events-none opacity-50" : ""
              } bg duration-350 flex items-center justify-center rounded-full bg-blue-500 py-1.5 px-3.5 font-medium tracking-tight text-white transition hover:bg-blue-600 active:bg-blue-700`}
            >
              {isLoading ? <FaSpinner className="mr-2 animate-spin" /> : ""}
              Save
            </button>
          </div>
        </form>
      </Card>
      <ToastContainer />
    </div>
  );
}

export default ToggleHistory;
