import React from "react";
import Card from "components/card";


const RecordingsPreview = ({ videoUrls }) => {
  return (
    <Card extra="!p-[20px] mt-5 grid h-full flex-cols grid-cols-12 w-full gap-5 ">
      {videoUrls.map((url, index) => (
        <div
          key={index}
          className="col-span-4 flex h-full w-full flex-col items-center justify-center"
        >
          <video controls width="100%">
            <source src={url} type="video/webm" />
          </video>
        </div>
      ))}
    </Card>
  );
};

export default RecordingsPreview;
