import Card from "components/card";
import React, { useState, useEffect } from "react";
import axios from 'axios';
import { useAuth } from "context/auth-context";

function Step1 (props) {
  const [client, setClient] = useState([]);
  const SERVER_URL = process.env.REACT_APP_API_BASE_URL;
  const { user } = useAuth();

  const formatDate = (date) => {
    if (!date) return ""; // Return empty string if date is null or undefined
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, "0");
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const year = d.getFullYear();
    return `${day}-${month}-${year}`;
  };

  useEffect(() => {
    const getData = async () => {
      await axios
        .get(`${SERVER_URL}/api/clients/${props.clientId}`, {
          headers: {
            'Authorization': `Bearer ${user.token}`
          }
        })
        .then((res) => setClient(res.data.data))
        .catch((err) => {
          console.log(err.message);
        });
    };

    getData();
  }, [SERVER_URL, props.clientId, user.token]);

  return (
    <Card extra={"w-full h-full p-3 "}>
      {/* Header */}
      <div className="mt-2 mb-8 w-full ">
        <h4 className="px-2 text-xl font-bold text-navy-700 dark:text-white">
          Session Start
        </h4>        
        <p className="mt-2 px-2 text-base text-gray-800 dark:text-white">
        In the XR simulation room, individuals with autism engage in immersive therapeutic experiences, fostering a supportive environment for sensory exploration and social interaction 
        </p>          

      </div>

      

      {/* Cards */}
      <div className="grid grid-cols-2 gap-4 px-2 ">
        <div className="flex flex-col items-start justify-center rounded-2xl bg-gray-800/5 backdrop-blur-xl bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:bg-[#0b14374d]/20 dark:shadow-none">
          <p className="text-sm text-gray-800 dark:text-white">Client</p>
          <p className="text-base font-medium text-navy-700 dark:text-white">
            {client.name}
          </p>
        </div>

        <div className="flex flex-col justify-center rounded-2xl bg-gray-800/5 backdrop-blur-xl bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:bg-[#0b14374d]/20 dark:shadow-none">
          <p className="text-sm text-gray-800 dark:text-white">QID</p>
          <p className="text-base font-medium text-navy-700 dark:text-white">
            {client.qid}
          </p>
        </div>

        <div className="flex flex-col items-start justify-center rounded-2xl bg-gray-800/5 backdrop-blur-xl bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:bg-[#0b14374d]/20 dark:shadow-none">
          <p className="text-sm text-gray-800 dark:text-white">Date of Birth</p>
          <p className="text-base font-medium text-navy-700 dark:text-white">
            {formatDate(client.dateofbirth)}
          </p>
        </div>

        <div className="flex flex-col justify-center rounded-2xl bg-gray-800/5 backdrop-blur-xl bg-clip-border px-3 py-4 shadow-3xl shadow-shadow-500 dark:bg-[#0b14374d]/20 dark:shadow-none">
          <p className="text-sm text-gray-800 dark:text-white">Gender</p>
          <p className="text-base font-medium text-navy-700 dark:text-white">
            {client.gender}
          </p>
        </div>
      </div>
      
    </Card>
  );
};

export default Step1;
