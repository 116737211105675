import React, { useRef, useEffect } from "react";
import axios from 'axios';
import { useAuth } from "context/auth-context";

const ScreenCapture = ({id, isScreenCaptureActive, isPreview, onRecordingComplete }) => {
  const videoRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const chunksRef = useRef([]);
  const { user } = useAuth();
  const SERVER_URL = process.env.REACT_APP_API_BASE_URL;
  const data = [];
  let stream;

  const shareScreen = async () => {
    if (navigator.mediaDevices.getDisplayMedia) {
      stream = await navigator.mediaDevices.getDisplayMedia({
        audio: true,
        video: true,
      });
      const mediaRecorder = new MediaRecorder(stream);

      mediaRecorder.ondataavailable = (event) => {
        // console.log(chnk.data)
        chunksRef.current.push(event.data);
      };
      mediaRecorder.start(1000);
      videoRef.current.srcObject = stream;
      mediaRecorderRef.current = mediaRecorder;

      mediaRecorder.onstop = async () => {
        const recordedBlob = new Blob(chunksRef.current, {
          type: "video/webm",
        });
        chunksRef.current = [];
        stream.getTracks().forEach(track => track.stop())

        // Using real-time local timestamp in the 'YYYY/MM/DD_HH:mm' format
        const currentDate = new Date();
        const timestamp =
          currentDate.getFullYear() +
          "_" +
          (currentDate.getMonth() + 1).toString().padStart(2, "0") +
          "_" +
          currentDate.getDate().toString().padStart(2, "0") +
          "_time_" +
          currentDate.getHours().toString().padStart(2, "0") +
          ":" +
          currentDate.getMinutes().toString().padStart(2, "0");

        const filename = `screenRecording_${timestamp}.webm`;

        // const url = URL.createObjectURL(recordedBlob);
        // const a = document.createElement("a");
        // a.href = url;

        // a.download = filename;
        // document.body.appendChild(a);
        // a.click();
        // document.body.removeChild(a);

        //upload to aws s3
        const formData = new FormData();
        formData.append('file', recordedBlob, filename);

        try {
          const response = await axios.post(`${SERVER_URL}/api/upload`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization': `Bearer ${user.token}`
            }
          });
          
          if (response.data && response.data.fileUrl) {
            onRecordingComplete(response.data.fileUrl, id); // Send recordingUrl to parent component
          }
        } catch (error) {
          console.error('Error uploading file:', error);
        }

      };
    }
  };

  const captureSteam = async () => {
    if (navigator.mediaDevices.getDisplayMedia) {
      stream = await navigator.mediaDevices.getDisplayMedia({
        audio: true,
        video: true,
      });
      const mr = new MediaRecorder(stream);

      mr.ondataavailable = (chnk) => {
        data.push(chnk.data);
      };
      mr.start(1000);

      mr.onstop = () => {
        stream.getTracks().forEach(track => track.stop())
      }

      //console.log("stream", stream);
      videoRef.current.srcObject = stream;
      mediaRecorderRef.current = mr;
    }
  };

  const stopScreenCapture = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      stream = null; // Clear the stream to stop the video
    }
  };

  useEffect(() => {
    if (isPreview) {
      captureSteam();
    } else if (isScreenCaptureActive) {
      shareScreen();
    } else {
      stopScreenCapture();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, isPreview, isScreenCaptureActive]);

  return (
    <video
      ref={videoRef}
      autoPlay
      playsInline
      width="100%"
      height="100%"
      style={{ objectFit: "cover" }}
    />
  );
};

export default ScreenCapture;
