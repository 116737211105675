import InputField from "components/fields/InputField";
import Checkbox from "components/checkbox";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAuth } from "context/auth-context";
import axios from "axios";

export default function SignIn() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [buttonText, setButtonText] = useState("Sign in");
  // const [show, setShow] = useState(false);
  const SERVER_URL = process.env.REACT_APP_API_BASE_URL;

  const navigate = useNavigate();

  const { setUser } = useAuth();
  const { user } = useAuth();
  // const handleClick = () => setShow(!show);

  useEffect(() => {
    if (user && user.token) {
      navigate("/admin/clients");
    }
  },[user, navigate])

  const login = async (event) => {
    if (event) {
      event.preventDefault();
    }
    if (user && user.token) {
      navigate("/admin/clients");
    }
    if (email === "") {
      return setError("You must enter your email.");
    }
    if (password === "") {
      return setError("You must enter your password");
    }
    setButtonText("Signing in");
    setIsLoading(true);
    try {
      const body = JSON.stringify({
        email: email,
        password: password
      });
      const res = await axios.post(
        `${SERVER_URL}/api/auth/login`, body,
        {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        }
      );
      if (res.status === 200 || res.status === 201) {
        setProfile(res);
      } else {
        setButtonText("Sign in");
        setIsLoading(false);
        setError(res.data.message);
      }
    } catch (err) {
      console.log(err);
      setButtonText("Sign in");
      setIsLoading(false);
      if (err.response.data.message) {
        setError(err.response.data.message);
      }
      setError("There has been an error.");
    }
  }
  const setProfile = async (response) => {
    let user = { ...response.data.user };
    user.token = response.data.token;
    user = JSON.stringify(user);
    setUser(user);
    localStorage.setItem("user", user);
    navigate("/admin/clients");
  };

  return (
    <div className=" mt-16 mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start ">
      {/* Sign in section */}
      <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white ">
          Sign in
        </h4>
        <p className="mb-9 ml-1 text-base text-gray-600">
          Enter your email and password to sign in!
        </p>

        {/* Email */}
        <InputField
          variant="auth"
          extra="mb-3"
          label="Email*"
          placeholder="hello@autismolab.com"
          id="email"
          type="email"
          value={email}
          onChange={(event) => {
            setEmail(event.target.value);
            setError(undefined);
          }}
        />

        {/* Password */}
        <InputField
          variant="auth"
          extra="mb-3"
          label="Password*"
          placeholder="Min. 8 characters"
          id="password"
          type="password"
          value={password}
          onChange={(event) => {
            setPassword(event.target.value);
            setError(undefined);
          }}
        />
        {/* Checkbox */}
        <div className="mb-4 flex items-center justify-between px-2">
          <div className="flex items-center">
            <Checkbox />
            <p className="ml-2 text-sm font-medium text-navy-700 dark:text-white">
              Keep me logged In
            </p>
          </div>
          <Link
            className="text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
            to="/auth/sign-in"
          >
            Forgot Password?
          </Link>
        </div>
        <div className="mb-2 flex items-center justify-start px-2">
          <h4 className="font-medium text-red-600">{error}</h4>
        </div>
        <button
          onClick={login}
          className="linear mt-2 flex w-full items-center justify-center rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
        >
          {buttonText}
          {isLoading ? (
            <div className="mt-2 ml-1 flex items-end gap-1">
              <i className="h-1 w-1 animate-bounce rounded-full bg-white [animation-delay:-0.3s]"></i>
              <i className="h-1 w-1 animate-bounce rounded-full bg-white [animation-delay:-0.15s]"></i>
              <i className="h-1 w-1 animate-bounce rounded-full bg-white"></i>
            </div>
          ) : (
            ""
          )}
        </button>
        <div className="mt-4">
          <span className=" text-sm font-medium text-navy-700 dark:text-gray-600">
            Not registered yet?
          </span>
          <Link
            to="/auth/sign-in"
            className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
          >
            Create an account
          </Link>
        </div>
      </div>
    </div>
  );
}
