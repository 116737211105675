import { Link } from "react-router-dom";
import Card from "components/card";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { useMemo, useState } from "react";
import { FiSearch } from "react-icons/fi";
import { Modal, ModalOverlay, ModalContent, ModalBody } from "@chakra-ui/modal";
import { useDisclosure } from "@chakra-ui/hooks";
import InputField from "components/fields/InputField";
import axios from "axios";
import { MdChevronLeft, MdChevronRight, MdDeleteOutline } from "react-icons/md";
import { AiOutlineUser } from "react-icons/ai";
import { BsPlay } from "react-icons/bs";
import Datepicker from "tailwind-datepicker-react";
import Dropdown from "components/dropdown";
import { BsThreeDots } from "react-icons/bs";
import ConfirmationModal from "./ConfirmationModal";
import { useAuth } from "context/auth-context";

const ClientsTableData = (props) => {
  const { columnsData, tableData } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [openMenu, setOpenMenu] = useState(false);
  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);
  const [showDatepicker, setShowDatepicker] = useState(false);
  const [clientData, setClientData] = useState({
    id: "",
    name: "",
    qid: "",
    contact: {
      email: "",
      mobilePhone: "",
    },
    nationality: "",
    birthdate: null,
    gender: "",
  });
  const [isEditing, setIsEditing] = useState(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [clientIdToRemove, setClientIdToRemove] = useState(null);
  const SERVER_URL = process.env.REACT_APP_API_BASE_URL;
  const { user } = useAuth();

  const handleChangeDatepicker = (selectedDate) => {
    const date = new Date(selectedDate);
    setClientData((prevClientData) => ({
      ...prevClientData,
      birthdate: date,
    }));
  };

  const formatDate = (date) => {
    if (!date) return ""; // Return empty string if date is null or undefined
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, "0");
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const year = d.getFullYear();
    return `${day}-${month}-${year}`;
  };

  // remove a client
  const handleRemoveClient = async (clientId) => {
    setClientIdToRemove(clientId);
    setIsConfirmationOpen(true);
  };

  const handleConfirmRemoveClient = async () => {
    try {
      setIsConfirmationOpen(false);
      const res = await axios.delete(
        `${SERVER_URL}/api/clients/${clientIdToRemove}`,
        {
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${user.token}`
          },
        }
      );
      if (res.status === 200 || res.status === 201) {
        window.location.reload();
      } else {
        console.log(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // edit an existing client
  const handleEditClient = (clientId) => {
    const client = tableData.find((client) => client.id === clientId);
    setClientData(client);
    setIsEditing(true);
    onOpen();
  };

  //create a new client
  const handleCreateClient = () => {
    setClientData({
      id: "",
      name: "",
      qid: "",
      contact: {
        email: "",
        mobilePhone: "",
      },
      nationality: "",
      birthdate: null,
      gender: "",
    });
    setIsEditing(false);
    onOpen();
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const body = JSON.stringify({
      name: clientData.name,
      qid: clientData.qid,
      dateofbirth: clientData.birthdate,
      contact: {
        email: clientData.contact.email,
        mobilePhone: clientData.contact.mobilePhone,
      },
      nationality: clientData.nationality,
      gender: clientData.gender,
    });
    try {
      if (isEditing) {
        //edit client
        const res = await axios.put(
          `${SERVER_URL}/api/clients/${clientData.id}`, // URL
          body, // Request body
          {
            headers: {
              "Content-Type": "application/json",
              'Authorization': `Bearer ${user.token}`
            },
          }
        );
        if (res.status === 200 || res.status === 201) {
          onClose();
          window.location.reload();
        } else {
          console.log(res.data);
        }
      } else {
        //create client
        const res = await axios.post(`${SERVER_URL}/api/clients/create`, body, {
          headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer ${user.token}`
          },
        });
        if (res.status === 200 || res.status === 201) {
          onClose();
          window.location.reload();
        } else {
          console.log(res.data);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const initialState = {
    pageSize: 10,
    pageIndex: 0,
  };

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    initialState: { pageIndex },
  } = tableInstance;

  return (
    <Card
      extra={"w-full h-full p-5 sm:overflow-x-auto bg-white backdrop-blur-xl"}
    >
      <div className="relative flex items-center justify-between">
        <div className="text-xl font-bold text-navy-700 dark:text-white">
          All Clients
        </div>
        {/* <CardMenu/> */}
      </div>

      <div className="clearfix relative w-full">
        <div className="float-left mt-5 flex h-[50px] items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white xl:w-[355px]">
          <p className="pl-3 pr-2 text-xl">
            <FiSearch className="h-4 w-4 text-gray-400 dark:text-white" />
          </p>
          <input
            type="text"
            placeholder="Search..."
            className="block h-full w-full rounded-full bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit"
          />
        </div>
        <div className="float-right mt-5 flex items-center justify-center">
          <button
            onClick={handleCreateClient}
            className="linear rounded-[20px] bg-brand-900 px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90"
          >
            Create Client
          </button>
        </div>
      </div>

      <ConfirmationModal
        isOpen={isConfirmationOpen}
        onClose={() => setIsConfirmationOpen(false)}
        onConfirm={handleConfirmRemoveClient}
      />

      <Modal isOpen={isOpen} onClose={onClose} size="xl" className="!z-[1010]">
        <ModalOverlay className="bg-[#000] !opacity-30" />
        <ModalContent className="!z-[1002] !m-auto !w-min min-w-[350px] !max-w-[85%] md:top-[12vh]">
          <ModalBody>
            <Card extra="px-[30px] pt-[35px] pb-[40px] max-w-[450px] flex flex-col !z-[1004]">
              <h1 className="font-bol`1x23d mb-[20px] text-2xl">
                {isEditing ? "Edit Client" : "Create a new client"}
              </h1>
              <div className="mb-[50px]">
                <form onSubmit={handleFormSubmit}>
                  <InputField
                    extra="mb-3"
                    label="Full Name*"
                    placeholder="Akram Hidri"
                    id="name"
                    type="text"
                    value={clientData.name}
                    onChange={(e) =>
                      setClientData({ ...clientData, name: e.target.value })
                    }
                  />

                  <InputField
                    extra="mb-3"
                    label="QID*"
                    placeholder="Min. 8 characters"
                    id="qid"
                    type="text"
                    value={clientData.qid}
                    onChange={(e) =>
                      setClientData({ ...clientData, qid: e.target.value })
                    }
                  />

                  <InputField
                    extra="mb-3"
                    label="Email*"
                    placeholder="hello@email.com"
                    id="email"
                    type="email"
                    value={clientData.contact.email}
                    onChange={(e) =>
                      setClientData({
                        ...clientData,
                        contact: {
                          ...clientData.contact,
                          email: e.target.value,
                        },
                      })
                    }
                  />

                  <InputField
                    extra="mb-3"
                    label="Phone*"
                    placeholder="55193000"
                    id="phone"
                    type="text"
                    value={clientData.contact.mobilePhone}
                    onChange={(e) =>
                      setClientData({
                        ...clientData,
                        contact: {
                          ...clientData.contact,
                          mobilePhone: e.target.value,
                        },
                      })
                    }
                  />
                  <div className="mt-2 flex flex-col gap-2">
                    <label
                      htmlFor="birthday"
                      className="ml-3 text-sm font-bold text-navy-700 dark:text-white"
                    >
                      Date of Birth
                    </label>
                    <Datepicker
                      className="absolute mb-4 flex w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 ps-10 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:border-blue-500 dark:focus:ring-blue-500"
                      onChange={handleChangeDatepicker}
                      show={showDatepicker}
                      setShow={(state) => setShowDatepicker(state)}
                      value={clientData.birthdate}
                    />
                  </div>

                  <InputField
                    extra="mb-3 mt-2"
                    label="Nationality"
                    placeholder="Qatar"
                    id="nationality"
                    type="text"
                    value={clientData.nationality}
                    onChange={(e) =>
                      setClientData({
                        ...clientData,
                        nationality: e.target.value,
                      })
                    }
                  />

                  <div className="flex w-72 flex-col gap-2">
                    <label
                      htmlFor="hs-select-gender"
                      className="ml-3 text-sm font-bold text-navy-700 dark:text-white"
                    >
                      Gender
                    </label>
                    <select
                      value={clientData.gender}
                      onChange={(e) =>
                        setClientData({ ...clientData, gender: e.target.value })
                      }
                      className="flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none"
                    >
                      <option></option>
                      <option value="MALE" id="m">
                        Male
                      </option>
                      <option value="FEMALE" id="f">
                        Female
                      </option>
                    </select>
                  </div>
                  <div className="flex gap-2">
                    <button
                      onClick={onClose}
                      type="reset"
                      className="linear mt-10 rounded-xl border-2 bg-red-500 px-5 py-3 text-base font-medium text-white transition duration-200 hover:bg-red-600 active:bg-red-700/5 dark:border-red-400 dark:bg-red-400/10 dark:text-white dark:hover:bg-red-300/10 dark:active:bg-red-200/10"
                    >
                      Close
                    </button>
                    <button
                      type="submit"
                      className="mt-10 rounded-xl bg-navy-700 px-5 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                    >
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </Card>
          </ModalBody>
        </ModalContent>
      </Modal>

      <div className="mt-6 h-full overflow-x-scroll xl:overflow-hidden">
        <table {...getTableProps()} className="w-full">
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    key={index}
                    className="w-[20rem] border-b border-gray-200 pr-5 pb-[10px] text-start dark:!border-navy-700"
                  >
                    <p className="text-xs tracking-wide text-gray-800 dark:text-white">
                      {column.render("Header")}
                    </p>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={index}>
                  {row.cells.map((cell, index) => {
                    let data = "";

                    if (cell.column.Header === "NAME") {
                      data = (
                        <p className="text-sm font-bold text-navy-700 dark:text-white">
                          {cell.value}
                        </p>
                      );
                    } else if (cell.column.Header === "QID") {
                      data = (
                        <p className="text-sm font-bold text-navy-700 dark:text-white">
                          {cell.value}
                        </p>
                      );
                    } else if (cell.column.Header === "EMAIL") {
                      data = (
                        <button className="bg-transparent m-1 rounded-full px-3 py-1 text-sm font-bold text-navy-700 transition duration-300 ease-in-out hover:bg-gray-800 hover:bg-opacity-25 dark:text-white">
                          {cell.value}
                        </button>
                      );
                    } else if (cell.column.Header === "PHONE NUMBER") {
                      data = (
                        <button className="bg-transparent m-1 rounded-full px-3 py-1 text-sm font-bold text-navy-700 transition duration-300 ease-in-out hover:bg-gray-800 hover:bg-opacity-25 dark:text-white">
                          {cell.value}
                        </button>
                      );
                    } else if (cell.column.Header === "DATE OF BIRTH") {
                      data = (
                        <p className="text-sm font-bold text-navy-700 dark:text-white">
                          {formatDate(cell.value)}
                        </p>
                      );
                    } else if (cell.column.Header === "NATIONALITY") {
                      data = (
                        <p className="text-sm font-bold text-navy-700 dark:text-white">
                          {cell.value}
                        </p>
                      );
                    } else if (cell.column.Header === "GENDER") {
                      data = (
                        <p className="text-sm font-bold text-navy-700 dark:text-white">
                          {cell.value}
                        </p>
                      );
                    } else if (cell.column.Header === "ACTION") {
                      data = (
                        <div className="">
                          <Dropdown
                            button={
                              <button
                                onClick={() => setOpenMenu(!openMenu)}
                                className={
                                  "linear flex items-center justify-center rounded-lg bg-lightPrimary p-2 text-xl font-bold text-brand-500 transition duration-200 hover:cursor-pointer hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10"
                                }
                              >
                                <BsThreeDots className="h-3 w-3" />
                              </button>
                            }
                            classNames={
                              "bottom-[-50px] -left-[40px] py-2 w-max"
                            }
                            animation={
                              "origin-top-right transition-all duration-300 ease-in-out"
                            }
                            children={
                              <div className="z-50 w-max rounded-xl bg-gray-100 py-3 px-4 text-sm shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                                <p className="hover:text-black flex cursor-pointer items-center gap-2 text-gray-600 hover:font-medium">
                                  <span>
                                    <BsPlay />
                                  </span>
                                  <Link
                                    to={{
                                      pathname: `/admin/XRsimulation/session/${cell.value}`,
                                    }}
                                  >
                                    <button className="flex text-sm text-gray-600 hover:text-gray-800 dark:text-white hover:dark:text-white">
                                      Start Simulation
                                    </button>
                                  </Link>
                                </p>
                                <p className="hover:text-black mt-2 flex cursor-pointer items-center gap-2 pt-1 text-gray-600 hover:font-medium">
                                  <span>
                                    <AiOutlineUser />
                                  </span>
                                  <button
                                    onClick={() => handleEditClient(cell.value)}
                                    className="flex text-sm text-gray-600 hover:text-gray-800 dark:text-white hover:dark:text-white"
                                  >
                                    Edit
                                  </button>
                                </p>
                                <p className="hover:text-black mt-2 flex cursor-pointer items-center gap-2 pt-1 text-gray-600 hover:font-medium">
                                  <span>
                                    <MdDeleteOutline />
                                  </span>
                                  <button
                                    onClick={() =>
                                      handleRemoveClient(cell.value)
                                    }
                                    className="flex text-sm text-red-600 hover:font-bold dark:text-white hover:dark:text-white"
                                  >
                                    Remove
                                  </button>
                                </p>
                              </div>
                            }
                          />
                        </div>
                      );
                    }

                    return (
                      <td
                        className="pt-[14px] pb-[18px] sm:text-[14px]"
                        {...cell.getCellProps()}
                        key={index}
                      >
                        {data}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="flex justify-end gap-1 sm:flex sm:items-end sm:justify-end">
          {/* pagination wrapper */}
          <div className="flex items-end justify-end gap-x-1">
            <button
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
              className="inline-flex min-h-[38px] min-w-[38px] items-center justify-center gap-x-1.5 rounded-lg py-2 px-2.5 text-sm text-gray-800 hover:bg-gray-100 focus:bg-gray-100 focus:outline-none disabled:pointer-events-none disabled:opacity-50 dark:text-white dark:hover:bg-white/10 dark:focus:bg-white/10"
            >
              <MdChevronLeft className="text-sm" />
              <span>Previous</span>
            </button>
            <div className="flex items-center gap-x-1">
              {(() => {
                const buttons = [];
                if (pageCount < 3) {
                  for (let index = 0; index < pageOptions.length; index++) {
                    buttons.push(
                      <button
                        key={index}
                        className="flex min-h-[38px] min-w-[38px] items-center justify-center rounded-lg py-2 px-3 text-sm text-gray-800 hover:bg-gray-100 focus:bg-gray-100 focus:outline-none disabled:pointer-events-none disabled:opacity-50 dark:text-white dark:hover:bg-white/10 dark:focus:bg-white/10"
                        aria-current="page"
                        onClick={() => gotoPage(index)}
                      >
                        {index + 1}
                      </button>
                    );
                  }
                  return buttons;
                } else {
                  for (let index = 0; index < 3; index++) {
                    buttons.push(
                      <button
                        type="button"
                        className="flex min-h-[38px] min-w-[38px] items-center justify-center rounded-lg py-2 px-3 text-sm text-gray-800 hover:bg-gray-100 focus:bg-gray-100 focus:outline-none disabled:pointer-events-none disabled:opacity-50 dark:text-white dark:hover:bg-white/10 dark:focus:bg-white/10"
                        aria-current="page"
                        onClick={() => gotoPage(index)}
                      >
                        {index + 1}
                      </button>
                    );
                  }
                  return buttons;
                }
              })()}

              {pageCount > 3
                ? () => {
                    // show next 3 pages
                    <div className="hs-tooltip inline-block">
                      <button
                        onClick={() => gotoPage(pageIndex + 3)}
                        className="hs-tooltip-toggle group flex min-h-[38px] min-w-[38px] items-center justify-center rounded-lg p-2 text-sm text-gray-400 hover:text-blue-600 focus:bg-gray-100 focus:outline-none disabled:pointer-events-none disabled:opacity-50 dark:text-gray-500 dark:hover:text-blue-500 dark:focus:bg-white/10"
                      >
                        <span className="text-xs group-hover:hidden">•••</span>
                        <svg
                          className="hidden h-5 w-5 flex-shrink-0 group-hover:block"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path d="m6 17 5-5-5-5" />
                          <path d="m13 17 5-5-5-5" />
                        </svg>
                        <span
                          className="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible dark:bg-slate-700 invisible absolute z-10 inline-block rounded bg-gray-900 py-1 px-2 text-xs font-medium text-white opacity-0 shadow-sm transition-opacity"
                          role="tooltip"
                        >
                          Next 3 pages
                        </span>
                      </button>
                      {/* show last page button */}
                      <button
                        onClick={() => gotoPage(pageCount - 1)}
                        className="flex min-h-[38px] min-w-[38px] items-center justify-center rounded-lg py-2 px-3 text-sm text-gray-800 hover:bg-gray-100 focus:bg-gray-100 focus:outline-none disabled:pointer-events-none disabled:opacity-50 dark:text-white dark:hover:bg-white/10 dark:focus:bg-white/10"
                      >
                        {pageOptions.length}
                      </button>
                    </div>;
                  }
                : null}
            </div>
            <button
              onClick={() => nextPage()}
              disabled={!canNextPage}
              className="inline-flex min-h-[38px] min-w-[38px] items-center justify-center gap-x-1.5 rounded-lg py-2 px-2.5 text-sm text-gray-800 hover:bg-gray-100 focus:bg-gray-100 focus:outline-none disabled:pointer-events-none disabled:opacity-50 dark:text-white dark:hover:bg-white/10 dark:focus:bg-white/10"
            >
              <span>Next</span>
              <MdChevronRight className="text-sm" />
            </button>
          </div>
          {/* page size dopdown */}

          <div className="flex w-32 flex-col justify-end gap-6">
            <select
              className="inline-flex w-32 items-center gap-x-1 rounded-lg border border-gray-200 py-1.5 px-2 text-sm text-gray-800 shadow-sm hover:bg-gray-50 focus:bg-gray-100 focus:outline-none disabled:pointer-events-none disabled:opacity-50 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 dark:focus:bg-gray-700"
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
            >
              {[10, 20, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </div>
          {/* End Dropdown */}
        </div>
      </div>
    </Card>
  );
};

export default ClientsTableData;
