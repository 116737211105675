import React from "react";
import { Modal, ModalOverlay, ModalContent, ModalBody } from "@chakra-ui/modal";
import Card from "components/card";

const ConfirmationModal = ({ isOpen, onClose, onConfirm }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay className="bg-[#000] !opacity-30" />
      <ModalContent className="!z-[1002] !m-auto !w-min min-w-[350px] !max-w-[85%] md:top-[12vh]">
        <ModalBody>
          <Card extra="text-center px-[30px] pt-[35px] pb-[40px] max-w-[450px] flex flex-col !z-[1004]">
            <h2 className="mb-2 text-lg font-semibold">Are you sure you want to remove this client?</h2>
            <p className="mb-4">Removing this client will remove all subsequent data, including simulation sessions, event history and survey responses. Are you sure you want to remove this client?</p>
            <div className="flex justify-center">
              <button
                onClick={onConfirm}
                className="mr-2 rounded-md bg-red-500 px-4 py-2 text-white hover:bg-red-600"
              >
                Remove
              </button>
              <button
                onClick={onClose}
                className="rounded-md bg-gray-400 px-4 py-2 text-white hover:bg-gray-500"
              >
                Cancel
              </button>
            </div>
          </Card>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmationModal;
