import React, { useState } from "react";

import Card from "components/card";
import Step from "components/Step/Step"
import Step1 from "./components/Step1"
import Step2 from "./components/Step2";
import Step3 from "./components/Step3";
//import Step4 from "./components/Step4";
import Step5 from "./components/Step5";
import SessionInfo from 'views/admin/SessionInfo'

import { useParams , useNavigate, useLocation} from "react-router-dom";
import axios from 'axios';
import { useAuth } from "context/auth-context";
import { FaSpinner } from "react-icons/fa";
import Step6 from "./components/Step6";
import SessionsList from "./components/SessionsList";

const XRSimulation = () => {
  //state variables
  const [step, setStep] = useState(1);
  const [disableContinueButton, setDisableContinueButton] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [sessionId, setSessionId] = useState("");
  const [preSurveyQuestions, setPreSurveyQuestions] = useState([]);
  const [postSurveyQuestions, setPostSurveyQuestions] = useState([]);
  //global variables
  const navigate = useNavigate();
  let params = useParams();
  const location = useLocation();
  const path = location.pathname.split("/", 4)
  let clientId = params.id;
  let preSurveyId = "65c9dacb3d3611baf6b77f6c";
  let postSurveyId = "65cdbfa7b5b73226a0e4e147";
  const SERVER_URL = process.env.REACT_APP_API_BASE_URL;
  const { user } = useAuth();

  function handleContinueButtonStateCallback() {
    setDisableContinueButton(false);
  };

  async function handleStepClick () {
    if (step === 1) {
      setDisableContinueButton(true);
      setIsLoading(true)
      // create a simulation session
      const body = {
        userId: user.id,
        clientId: clientId,
        simulationId: "658830d664542b6731582c1d",
        startedAt: new Date().toISOString(), //add startedAt
        status: "CREATED"
      };
      try {
        //create a simulation session
        await axios
          .post(`${SERVER_URL}/api/session/create`, body, {
            headers: {
              'Authorization': `Bearer ${user.token}`
            }
          })
          .then((res) => setSessionId(res.data.data.id))
          .catch((err) => console.log(err.message));
        //get pre survey questions
        await axios
          .get(`${SERVER_URL}/api/survey/${preSurveyId}/`, {
            headers: {
              'Authorization': `Bearer ${user.token}`
            }
          })
          .then((res) => setPreSurveyQuestions(res.data.data.questions))
          .catch((err) => {
            console.log(err.message);
          });
          //get post survey questions
          await axios
          .get(`${SERVER_URL}/api/survey/${postSurveyId}/`, {
            headers: {
              'Authorization': `Bearer ${user.token}`
            }
          })
          .then((res) => setPostSurveyQuestions(res.data.data.questions))
          .catch((err) => {
            console.log(err.message);
          });
          setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    }
    else if (step === 2) {
      setDisableContinueButton(false);
    }
    else if (step === 4){
      const VRBody = {
        dataType: "from_plane_pawn",
        from_plane_pawn: "reset_gameplay",
      };
      try {
        await axios.put(`${SERVER_URL}/api/vrdata`, VRBody, {
          headers: {
            'Authorization': `Bearer ${user.token}`
          }
        });
      } catch (error) {
        console.log(error);
      }
      setDisableContinueButton(true);
    }
    else if (step === 5){
      // update endedAt session in the database
      setDisableContinueButton(true);
      setIsLoading(true)
      const body = {
        endedAt: new Date().toISOString(),
        status: "COMPLETED"
      };
      try {
        await axios
        .put(`${SERVER_URL}/api/session/update/${sessionId}`, body, {
          headers: {
            'Authorization': `Bearer ${user.token}`
          }
        })
        .then(() => {
          //do something with the data
          //console.log(res.data);
          setIsLoading(false);
          //navigate user back to XR simulation page.
          navigate("/admin/XRsimulation");
        })
        .catch((err) => console.log(err.message));
      } catch (error) {
        console.log(error);
      }
    }
    //update step id
    setStep(step > 4 ? step : step + 1);
  }


  return (
    <Card
      extra={
        "w-full h-full mt-10 p-5 sm:overflow-x-auto bg-white backdrop-blur-xl"
      }
    >
      {path[3] === 'session' ? (
        <div className="bg-transparent mx-auto h-full w-full rounded-lg">
          <div className="flex justify-between rounded p-8">
            <Step step={1} currentStep={step} />
            <Step step={2} currentStep={step} />
            {/* <Step step={3} currentStep={step} />*/}
            <Step step={3} currentStep={step} /> 
            <Step step={4} currentStep={step} />
            <Step step={5} currentStep={step} />
          </div>

          {/* Dynamic content based on `step` */}
          <div className="flex h- w-full grid-cols-1 gap-5 space-y-20 px-8 lg:!grid-cols-12">
            {step === 1 && <Step1 clientId={clientId} />}
            {step === 2 && (
              <Step2
                clientId={clientId}
                sessionId={sessionId}
                surveyId={preSurveyId}
                preSurveyQuestions={preSurveyQuestions}
                enableContinueButton={handleContinueButtonStateCallback}
              />
            )}
             {step === 3 && <Step3 />}
            {/* {step === 4 && <Step4 />} */}
            {step === 4 &&
                <Step5 
                sessionId={sessionId} 
              />
            }
            {step === 5 && (
              <Step6
                clientId={clientId}
                sessionId={sessionId}
                surveyId={postSurveyId}
                postSurveyQuestions={postSurveyQuestions}
                enableContinueButton={handleContinueButtonStateCallback}
              />
            )}
          </div>

          {/* wizard navigation buttons */}
          <div className="px-8 pb-8">
            <div className="mt-10 flex justify-between">
              <button
                onClick={() => {
                  setStep(step < 2 ? step : step - 1);
                  setDisableContinueButton(false);
                }}
                className={`${
                  step === 1 ? "pointer-events-none opacity-50" : ""
                } duration-350 text-neutral-400 hover:text-neutral-700 rounded px-2 py-1 transition`}
              >
                Back
              </button>
              <button
                onClick={() => {
                  handleStepClick();
                }}
                className={`${
                  disableContinueButton ? "pointer-events-none opacity-50" : ""
                } bg duration-350 flex items-center justify-center rounded-full bg-blue-500 py-1.5 px-3.5 font-medium tracking-tight text-white transition hover:bg-blue-600 active:bg-blue-700`}
              >
                {isLoading ? <FaSpinner className="mr-2 animate-spin" /> : ""}
                {step === 1 && "Start Session"}
                {step === 2 && "Continue"}
                {step === 3 && "Continue"}
                {step === 4 && "Continue"}
                {step === 5 && "Finish Session"}
              </button>
            </div>
          </div>
        </div>
      ) : path[3] === undefined ? (
        <SessionsList />
      ) : path[3] === 'sessioninfo' ? (
        <SessionInfo />
      ) : null}
    </Card>
  );
};

export default XRSimulation;
