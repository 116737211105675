import Card from "components/card";
import React from "react";
import Sceneonplane from "./Sceneonplane";

const General = ({sessionId}) => {
  return (
    <Card extra={"w-full  p-3 "}>
      {/* Header */}
      <div className="mt-2 mb-3 w-full ">
        <h4 className="px-2 text-xl font-bold text-navy-700 dark:text-white">
          On Airplane Simulation
        </h4>
      </div>

      <Sceneonplane sessionId={sessionId} />
    
    </Card>
  );
};

export default General;
