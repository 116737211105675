import React from "react";
import avatar from "assets/img/avatars/avatarSimmmple.png";

const Banner = ({ clientProfile }) => {
  return (
    <div extra={"items-center w-full pb-10 p-[16px] bg-cover bg-gray-800/0"}>
      {/* Background and profile */}
      <div className="relative mt-1 flex h-24 w-full justify-center bg-cover">
        <div className="absolute flex h-[87px] w-[87px] items-center justify-center rounded-full border-[4px] border-white bg-pink-400 dark:!border-navy-700">
          <img className="h-full w-full rounded-full" src={avatar} alt="" />
        </div>
      </div>

      {/* Name and position */}
      <div className="mt-1 flex flex-col items-center">
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
          {clientProfile?.name}
        </h4>
      </div>

      {/* Post followers */}
      <div className="mt-5 mb-5 items-center justify-center flex gap-5 md:!gap-20">
        <div className="flex flex-col items-center justify-center">
          <p className="text-2xl font-bold text-navy-700 dark:text-white">50</p>
          <p className="text-sm font-normal text-gray-800 dark:text-white">Start</p>
        </div>
        <div className="flex flex-col items-center justify-center">
          <p className="text-2xl font-bold text-navy-700 dark:text-white">
            75
          </p>
          <p className="text-sm font-normal text-gray-800 dark:text-white">End</p>
        </div>
        <div className="flex flex-col items-center justify-center">
          <p className="text-2xl font-bold text-navy-700 dark:text-white">
            200
          </p>
          <p className="text-sm font-normal text-gray-800 dark:text-white">Session Duration</p>
        </div>
      </div>
    </div>
  );
};

export default Banner;
