import React, { useState } from 'react';
import Card from "components/card";
import WebcamComponent from './WebcamComponent';
import { MdCameraAlt } from "react-icons/md";

import "./Banner.css"; // Import your CSS file for styling

const Cameraone = () => {
  const [isPreview, setIsPreview] = useState(false);



  return (
    <Card extra={"w-full  p-3 "}>
      {/* Header */}
      <div className="mt-2 mb-8 w-full ">
        <h4 className="flex items-center justify-center px-2 text-xl font-bold text-navy-700 dark:text-white">
          Webcam No.1
        </h4>
      </div>
      <Card
        extra={
          "mb-5 items-center w-full h-full  p-[16px] bg-cover bg-gray-800/5 backdrop-blur-xl"
        }
      >
        <div className="relative mt-1 flex h-full w-full justify-center rounded-xl">
          <WebcamComponent id={1} isPreview={isPreview} />
        </div>
        <div className="mt-6 mb-3 flex gap-3 md:!gap-2">
          <div className="flex flex-col items-center justify-center">
            <button
              onClick={() => {setIsPreview(true)}}
              className={`linear rounded-[20px] px-2 py-0.5 text-sm font-normal text-white transition duration-200 ${
                isPreview ? "recording-btn" : "not-recording-btn"
              }`}
            >
              <div className="flex items-center">
                <div className="flex h-7 w-7 items-center justify-center">
                  <MdCameraAlt className="h-5 w-5 text-sm font-normal text-white dark:text-white" />
                </div>
                <h4 className="ml-1 mr-2 text-sm font-normal text-white dark:text-white">
                  Preview
                </h4>
              </div>
            </button>
          </div>
        </div>
      </Card>
    </Card>
  );
};

export default Cameraone;