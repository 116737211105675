import Footer from "components/footer/FooterAuthDefault";
import SignIn from "../../views/auth/SignIn/SignIn";
import authImg from "assets/img/auth/auth.png";
import FixedPlugin from "components/fixedPlugin/FixedPlugin";
import { Routes, Route, Navigate } from "react-router-dom";
import { useState } from "react";
import routes from "routes.js";

import { SidebarContext } from "context/SidebarContext";

export default function Auth() {
  const [toggleSidebar, setToggleSidebar] = useState(false);
  // const getRoute = () => {
  //   return window.location.pathname !== "/auth/full-screen-maps";
  // };
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
          <Route path={`/${prop.path}`} element={prop.component} key={key} />
        );
      }
      if (prop.collapse) {
        return getRoutes(prop.items);
      }
      if (prop.category) {
        return getRoutes(prop.items);
      } else {
        return null;
      }
    });
  };

  document.documentElement.dir = "ltr";
  return (
    <SidebarContext.Provider
      value={{
        toggleSidebar,
        setToggleSidebar,
      }}
    >
      <div className="relative float-right h-full min-h-screen w-full rounded-[0rem] bg-white/10 backdrop-blur-xl dark:!bg-navy-900">
        <FixedPlugin />
        <main className={`mx-auto min-h-screen`}>
          <div className="relative flex">
            <div className="mx-auto flex min-h-full w-full flex-col justify-start pt-12 md:max-w-[75%] lg:h-screen lg:max-w-[1013px] lg:px-8 lg:pt-0 xl:h-[100vh] xl:max-w-[1383px] xl:px-0 xl:pl-[70px]">
              <div className="mb-auto flex flex-col pl-5 pr-5 md:pr-0 md:pl-12 lg:max-w-[48%] lg:pl-0 xl:max-w-full">
                <div>
                  <SignIn />
                </div>

                <Routes>
                  {getRoutes(routes)}
                  <Route
                    path="/"
                    element={<Navigate to="/auth/sign-in" replace />}
                  />
                </Routes>
                <div className="absolute right-0 hidden h-full min-h-screen md:block lg:w-[49vw] 2xl:w-[44vw]">
                  <div
                    className="absolute flex h-full w-full items-end justify-center rounded-[0rem] bg-cover  bg-center"
                    style={{ backgroundImage: `url(${authImg})` }}
                  />
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </main>
      </div>
    </SidebarContext.Provider>
  );
}
