import React from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { useAuth } from "../context/auth-context";
import axios from "axios";

export const ProtectedRoute = () => {
  const { user, setUser } = useAuth();
  const navigate = useNavigate();
  const SERVER_URL = process.env.REACT_APP_API_BASE_URL;

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response && error.response.status === 401) {
        // navigate to the login screen
        navigate("/auth/sign-in");
        // Throw an exception to stop further execution
        return Promise.reject("Unauthorized");
      }
      // Handle other errors here
      return Promise.reject(error);
    }
  );

  if (user && user.token) {
    axios
      .get(`${SERVER_URL}/api/auth/verifyToken`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      })
      .then((response) => {
        if (!response.data.valid) {
          setUser(null);
          localStorage.removeItem("user");
          return <Navigate to="/auth/sign-in" replace />;
        }
      })
      .catch((error) => {
        if (error === "Unauthorized") {
          setUser(null);
          localStorage.removeItem("user");
          return <Navigate to="/auth/sign-in" replace />;
        }
      });
  }

  if (!user || !user.token || user.token === "") {
    return <Navigate to="/auth/sign-in" replace />;
  }

  return <Outlet />;
};
