import React from 'react';
import { Routes, Route, Navigate } from "react-router-dom";
import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/auth";
import { AuthProvider } from './context/auth-context';
import { ProtectedRoute } from 'layouts/protected.route';

const App = () => {
  let user = localStorage.getItem("user");
  user = JSON.parse(user);

  return (
    <AuthProvider userData={user}>
        <Routes>
          <Route path="/" element={<Navigate to="/auth" replace />} />
          <Route path="auth/*" element={<AuthLayout />} />
          <Route element={<ProtectedRoute />}>
            <Route path="admin/*" element={<AdminLayout />} />
          </Route>
        </Routes>
    </AuthProvider>
  );
};

export default App;
