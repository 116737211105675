import Card from "components/card";
import React, { useState } from "react";
import axios from 'axios';
import InputField from "components/fields/InputField";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaSpinner } from "react-icons/fa";
import { useAuth } from "context/auth-context";

function Step6 ({sessionId, clientId, surveyId, postSurveyQuestions, enableContinueButton}) {
  const [surveyAnswers, setSurveyAnswers] = useState([]);
  const [disableSaveButton, setDisableSaveButton] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const SERVER_URL = process.env.REACT_APP_API_BASE_URL;
  const { user } = useAuth();

  const handleFormField = (e, id) => {
    const { value } = e.target;
    setSurveyAnswers(prevData => {
      const updatedData = [...prevData];
      updatedData[id] = {
        answerText: value ? value : '',
        questionId: e.target.id,
        surveyId: surveyId
      };
      return updatedData;
    });
  };

  const handleSurvey = async (e) => {
    e.preventDefault();
    setDisableSaveButton(true);
    setIsLoading(true);
    const body = {
      surveyId: surveyId,
      answers: surveyAnswers,
      sessionId: sessionId,
      userId: user.id,
      clientId: clientId
    }
    try {
      await axios
        .post(`${SERVER_URL}/api/survey/responses/create`, body, {
          headers: {
            'Authorization': `Bearer ${user.token}`
          }
        })
        .then(() => {
          //console.log(res.data);
          //do something with the data
          toast("🚀  Data saved successfully!");
          setIsLoading(false);
          //send props back to parent to enable the continue button.
          enableContinueButton();
        })
        .catch((err) => console.log(err.message));
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Card extra={"w-full h-[500px] overflow-auto p-3 "}>
      {/* Header */}
      <div className="mt-2 mb-8 w-full ">
        <h4 className="px-2 text-xl font-bold text-navy-700 dark:text-white">
          Post-Simulation Survey
        </h4>
        <p className="mt-2 px-2 text-base text-gray-800 dark:text-white">
          In the XR simulation room, individuals with autism engage in immersive
          therapeutic experiences, fostering a supportive environment for
          sensory exploration and social interaction
        </p>
      </div>

      {/* post survey form */}

      <form
        onSubmit={handleSurvey}
        className="mt-8 mb-2 w-3/4"
      >
        <div className="mb-1 flex flex-col gap-6">
          {postSurveyQuestions.map((question, id) => (
            <InputField
              id={question.id}
              key={id}
              type="text"
              placeholder="Answer here"
              extra=" !border-t-blue-gray-200 focus:!border-t-gray-900"
              label={question.questionText}
              onChange={(e) => handleFormField(e, id)}
            />
          ))}
        </div>
        <ToastContainer />
        <div className="mt-4 ml-2 flex gap-2">
          <button
            type="submit"
            className={`${
              disableSaveButton ? "pointer-events-none opacity-50" : ""
            } bg duration-350 flex items-center justify-center rounded-full bg-blue-500 py-1.5 px-3.5 font-medium tracking-tight text-white transition hover:bg-blue-600 active:bg-blue-700`}
          >
            {isLoading? <FaSpinner className="mr-2 animate-spin" /> : ""}
            Save
          </button>
        </div>
      </form>
    </Card>
  );
};

export default Step6;
