import axios from "axios";

export async function startPlane(SERVER_URL, user) {
  const body = {
    dataType: "flightsimulation",
    flightsimulation: "movetorunway",
  };

  try {
    await axios.put(`${SERVER_URL}/api/vrdata`, body, {
      headers: {
        'Authorization': `Bearer ${user.token}`
      }
    });
  } catch (err) {
    console.error(err);
  }
}

export async function stopPlane(SERVER_URL, user) {
  const body = {
    dataType: "flightsimulation",
    flightsimulation: "stopmovetorunway",
  };

  try {
    await axios.put(`${SERVER_URL}/api/vrdata`, body, {
      headers: {
        'Authorization': `Bearer ${user.token}`
      }
    });
  } catch (err) {
    console.error(err);
  }
}

export async function showPeople(SERVER_URL, user) {
  const body = {
    dataType: "peoplevisibility",
    peoplevisibility: "showpeople",
  };

  try {
    await axios.put(`${SERVER_URL}/api/vrdata`, body, {
      headers: {
        'Authorization': `Bearer ${user.token}`
      }
    });
  } catch (err) {
    console.error(err);
  }
}

export async function hidePeople(SERVER_URL, user) {
  const body = {
    dataType: "peoplevisibility",
    peoplevisibility: "hidepeople",
  };

  try {
    await axios.put(`${SERVER_URL}/api/vrdata`, body, {
      headers: {
        'Authorization': `Bearer ${user.token}`
      }
    });
  } catch (err) {
    console.error(err);
  }
}

export async function turnEngineOn(SERVER_URL, user) {
  const body = {
    dataType: "flightintroduction",
    flightintroduction: "flightintroductionOn",
  };

  try {
    await axios.put(`${SERVER_URL}/api/vrdata`, body, {
      headers: {
        'Authorization': `Bearer ${user.token}`
      }
    });
  } catch (err) {
    console.error(err);
  }
}

export async function turnEngineOff(SERVER_URL, user) {
  const body = {
    dataType: "flightintroduction",
    flightintroduction: "flightintroductionOff",
  };

  try {
    await axios.put(`${SERVER_URL}/api/vrdata`, body, {
      headers: {
        'Authorization': `Bearer ${user.token}`
      }
    });
  } catch (err) {
    console.error(err);
  }
}

export async function instructionOn(SERVER_URL, user) {
  const body = {
    dataType: "flightinstruction",
    flightinstruction: "playinstruction",
  };

  try {
    await axios.put(`${SERVER_URL}/api/vrdata`, body, {
      headers: {
        'Authorization': `Bearer ${user.token}`
      }
    });
  } catch (err) {
    console.error(err);
  }
}

export async function instructionOff(SERVER_URL, user) {
  const body = {
    dataType: "flightinstruction",
    flightinstruction: "stopinstruction",
  };

  try {
    await axios.put(`${SERVER_URL}/api/vrdata`, body, {
      headers: {
        'Authorization': `Bearer ${user.token}`
      }
    });
  } catch (err) {
    console.error(err);
  }
}

export async function ceilingLightOn(SERVER_URL, user) {
  const API_URL_1 = `${SERVER_URL}/api/vrdata`;
  const API_URL_2 = `${SERVER_URL}/api/vrdata`;
  const body1 = {
    dataType: "CeilingIntensity",
    CeilingIntensity: "{ valueintensity: 1 }",
  };

  const body2 = {
    dataType: "Ceilingcolortest",
    Ceilingcolortest: "{ colorforceiling: [210, 121, 145] }",
  };

  try {
    await Promise.all([
      axios.put(API_URL_1, body1, { headers: { 'Authorization': `Bearer ${user.token}`}}),
      axios.put(API_URL_2, body2, { headers: { 'Authorization': `Bearer ${user.token}`}}),
    ]);
  } catch (err) {
    console.error(err.response || err);
  }
}

export async function ceilingLightOff(SERVER_URL, user) {
  const API_URL_1 = `${SERVER_URL}/api/vrdata`;
  const API_URL_2 = `${SERVER_URL}/api/vrdata`;
  const body1 = {
    dataType: "CeilingIntensity",
    CeilingIntensity: "{ valueintensity: 0 }",
  };

  const body2 = {
    dataType: "Ceilingcolortest",
    Ceilingcolortest: "{ colorforceiling: [210, 121, 145] }",
  };

  try {
    await Promise.all([
      axios.put(API_URL_1, body1, { headers: { 'Authorization': `Bearer ${user.token}`}}),
      axios.put(API_URL_2, body2, { headers: { 'Authorization': `Bearer ${user.token}`}}),
    ]);
  } catch (err) {
    console.error(err.response || err);
  }
}

export async function windowLightOn(SERVER_URL, user) {
  const API_URL_3 = `${SERVER_URL}/api/vrdata`;
  const API_URL_4 = `${SERVER_URL}/api/vrdata`;
  const body1 = {
    dataType: "windowlightintensity",
    windowlightintensity: "{ windowvalueintensity: 1 }",
  };

  const body2 = {
    dataType: "windowcolorlight",
    windowcolorlight: "{ colorforwindow: [210, 121, 145] }",
  };

  try {
    await Promise.all([
      axios.put(API_URL_3, body1, { headers: { 'Authorization': `Bearer ${user.token}`}}),
      axios.put(API_URL_4, body2, { headers: { 'Authorization': `Bearer ${user.token}`}}),
    ]);
  } catch (err) {
    console.error(err.response || err);
  }
}

export async function windowLightOff(SERVER_URL, user) {
  const API_URL_3 = `${SERVER_URL}/api/vrdata`;
  const API_URL_4 = `${SERVER_URL}/api/vrdata`;
  const body1 = {
    dataType: "windowlightintensity",
    windowlightintensity: "{ windowvalueintensity: 0 }",
  };

  const body2 = {
    dataType: "windowcolorlight",
    windowcolorlight: "{ colorforwindow: [210, 121, 145] }",
  };

  try {
    await Promise.all([
      axios.put(API_URL_3, body1, { headers: { 'Authorization': `Bearer ${user.token}`}}),
      axios.put(API_URL_4, body2, { headers: { 'Authorization': `Bearer ${user.token}`}}),
    ]);
  } catch (err) {
    console.error(err.response || err);
  }
}
