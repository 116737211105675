//import React, { useContext, useEffect, useState } from 'react';
import banner from "assets/img/profile/onplane.jpg";
import Card from "components/card";
import axios from "axios";
import { useAuth } from "context/auth-context";
// Icon Imports
import {
  MdChangeCircle,
  MdOutlinePlayCircle,
  MdOutlineStopCircle,
} from "react-icons/md";
import { FaSpinner } from "react-icons/fa";
import { useState, useCallback } from "react";
import WebcamComponent from "./WebcamComponent";
import ScreenCapture from "./ScreenCapture";

const Banner = ({ sessionId }) => {
  const [isRecording, setIsRecording] = useState(false);
  const [isPreview, setIsPreview] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const SERVER_URL = process.env.REACT_APP_API_BASE_URL;
  const { user } = useAuth();

  const handleRecordingComplete = useCallback(
    async (recordingUrl, id) => {
      // update session
      let body = {};
      if (id === 0) {
        body = {
          RecordingURL3: recordingUrl,
        };
      } else if (id === 1) {
        body = {
          RecordingURL1: recordingUrl,
        };
      } else if (id === 2) {
        body = {
          RecordingURL2: recordingUrl,
        };
      }
      try {
        await axios.put(`${SERVER_URL}/api/session/update/${sessionId}`, body, {
          headers: {
            'Authorization': `Bearer ${user.token}`
          }
        });
        //console.log(res.data);
        setIsUploading(false);
        setIsPlaying(false);
      } catch (error) {
        console.log(error);
      }
    },
    [SERVER_URL, sessionId, user.token]
  );

  async function onPlay() {
    // update the session status
    setIsLoading(true);
    const body = {
      status: "IN_PROGRESS",
    };
    try {
      await axios.put(`${SERVER_URL}/api/session/update/${sessionId}`, body, {
        headers: {
          'Authorization': `Bearer ${user.token}`
        }
      });
      //console.log(res.data);
    } catch (error) {
      console.log(error);
    }
    // First request to set from_plane_pawn to "reset_gameplay"
    const Body = {
      dataType: "from_home_pawn",
      from_home_pawn: "null",
    };

    try {
      await axios.put(`${SERVER_URL}/api/vrdata`, Body, {
        headers: {
          'Authorization': `Bearer ${user.token}`
        }
      });

      // Simulate a delay (e.g., 500 milliseconds) and then send a second request to set from_plane_pawn to "null"
      setTimeout(async () => {
        const Body = {
          dataType: "from_home_pawn",
          from_home_pawn: "to_airplane_pawn",
        };

        try {
          await axios.put(`${SERVER_URL}/api/vrdata`, Body, {
            headers: {
              'Authorization': `Bearer ${user.token}`
            }
          });
          setIsPlaying(true);
        } catch (nullErr) {
          console.error(nullErr);
        }
      }, 1000);
    } catch (resetErr) {
      console.error(resetErr);
    }
    setIsRecording(true);
    setIsPreview(false);
    setIsLoading(false);
  }

  async function onClickReset() {
    // First request to set from_plane_pawn to "reset_gameplay"
    const Body = {
      dataType: "from_plane_pawn",
      from_plane_pawn: "reset_gameplay",
    };

    setIsPlaying(false);

    try {
      await axios.put(`${SERVER_URL}/api/vrdata`, Body, {
        headers: {
          'Authorization': `Bearer ${user.token}`
        }
      });

      // Simulate a delay (e.g., 500 milliseconds) and then send a second request to set from_plane_pawn to "null"
      setTimeout(async () => {
        const Body = {
          dataType: "from_plane_pawn",
          from_plane_pawn: "null",
        };

        try {
          await axios.put(`${SERVER_URL}/api/vrdata`, Body, {
            headers: {
              'Authorization': `Bearer ${user.token}`
            }
          });
        } catch (nullErr) {
          console.error(nullErr);
        }
      }, 800);
    } catch (resetErr) {
      console.error(resetErr);
    }
  }

  function onStopSession() {
    setIsRecording(false);
    setIsPreview(false);
    setIsUploading(true);
    // setTimeout(() => setIsUploading(false), 800)
  }

  return (
    <Card
      extra={
        "mb-5 items-center w-full  p-[16px] bg-cover bg-gray-800/5 backdrop-blur-xl"
      }
    >
      {/* VR and cameras preview */}
      {!isPlaying ? (
        <div
          className="relative mt-1 flex w-full justify-center rounded-xl bg-cover bg-center"
          style={{
            backgroundImage: `url(${banner})`,
            height: "300px", // Set a default height for larger screens
          }}
        ></div>
      ) : (
        <div className="mt-1 grid w-full grid-flow-row-dense grid-cols-3 grid-rows-2 gap-0">
          <div className="col-span-2 row-span-2 flex h-full w-full flex-col bg-cover">
            <ScreenCapture
              id={0}
              isScreenCaptureActive={isRecording}
              isPreview={isPreview}
              onRecordingComplete={handleRecordingComplete}
            />
          </div>

          <div className="flex h-full w-full flex-col bg-cover">
            <WebcamComponent
              id={1}
              isRecording={isRecording}
              isPreview={isPreview}
              onRecordingComplete={handleRecordingComplete}
            />
          </div>
          <div className="flex h-full w-full flex-col bg-cover">
            <WebcamComponent
              id={2}
              isRecording={isRecording}
              isPreview={isPreview}
              onRecordingComplete={handleRecordingComplete}
            />
          </div>
        </div>
      )}

      <div className="mt-6 mb-3 flex flex-col gap-4 sm:flex-row md:!gap-4">
        <div className="flex flex-col items-center justify-center">
          <button
            className={`${
              isPlaying ? "pointer-events-none opacity-50" : ""
            } linear rounded-[20px] bg-brand-900 px-2 py-0.5 text-sm font-normal text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90`}
            onClick={onPlay}
          >
            <div className="flex items-center">
              {isLoading ? (
                <div className="flex h-7 w-7 items-center justify-center ">
                  <FaSpinner className="h-4 w-4 animate-spin" />
                </div>
              ) : (
                <div className="flex h-7 w-7 items-center justify-center ">
                  <MdOutlinePlayCircle className="h-5 w-5 text-sm font-normal text-white dark:text-white" />
                </div>
              )}
              <h4 className="ml-1 mr-2 text-sm font-normal text-white dark:text-white">
                Play
              </h4>
            </div>
          </button>
        </div>
        {/* <div className="flex flex-col items-center justify-center">
            <button
              className="linear rounded-[20px] bg-brand-900 px-2 py-0.5 text-sm font-normal text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90"
              onClick={onRecord}
            >
              <div className="flex items-center">
                <div className="flex h-7 w-7 items-center justify-center ">
                  <MdNotStarted className="h-5 w-5 text-sm font-normal text-white dark:text-white" />
                </div>
                <h4 className="ml-1 mr-2 text-sm font-normal text-white dark:text-white">
                  Start Recording
                </h4>
              </div>
            </button>
          </div> */}
        <div className="flex flex-col items-center justify-center">
          <button
            className={`${
              !isPlaying ? "pointer-events-none opacity-50" : ""
            } linear rounded-[20px] bg-red-500 px-1 py-0.5 text-sm font-normal text-white transition duration-200 hover:bg-red-800 active:bg-brand-700 dark:bg-red-500 dark:hover:bg-red-400 dark:active:opacity-90`}
            onClick={onStopSession}
          >
            <div className="flex items-center">
              {isUploading ? (
                <div className="flex h-7 w-7 items-center justify-center ">
                  <FaSpinner className="h-4 w-4 animate-spin" />
                </div>
              ) : (
                <div className="flex h-7 w-7 items-center justify-center ">
                  <MdOutlineStopCircle className="h-5 w-5 text-sm font-normal text-white dark:text-white" />
                </div>
              )}
              <h4 className="ml-1 mr-2 text-sm font-normal text-white dark:text-white">
                Stop Session
              </h4>
            </div>
          </button>
        </div>
        <div className="flex flex-col items-center justify-center">
          <button
            className="linear rounded-[20px] bg-brand-900 px-2 py-0.5 text-sm font-normal text-white transition duration-200 hover:bg-brand-800 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90"
            onClick={onClickReset}
          >
            <div className="flex items-center">
              <div className="flex h-7 w-7 items-center justify-center ">
                <MdChangeCircle className="h-5 w-5 text-sm font-normal text-white dark:text-white" />
              </div>
              <h4 className="ml-1 mr-2 text-sm font-normal text-white dark:text-white">
                Reset
              </h4>
            </div>
          </button>
        </div>
      </div>
    </Card>
  );
};

export default Banner;
