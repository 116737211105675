import React, { useRef, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from 'context/auth-context';

function WebcamComponent({ id, isRecording, isPreview, onRecordingComplete }) {
  const videoRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const chunksRef = useRef([]);
  const SERVER_URL = process.env.REACT_APP_API_BASE_URL;
  const { user } = useAuth();
  console.log(user);

  useEffect(() => {
    const startPreview = async () => {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const cameras = devices.filter(device => device.kind === 'videoinput');
        const audioDevices = devices.filter(device => device.kind === 'audioinput');

        if (cameras.length > id && audioDevices.length > 0) {
          const stream = await navigator.mediaDevices.getUserMedia({
            video: { deviceId: cameras[id].deviceId },
            audio: {
              deviceId: audioDevices[0].deviceId,
              sampleRate: 44100,
              channelCount: 2,
            },
          });

          if (videoRef.current) {
            videoRef.current.srcObject = stream;

            mediaRecorderRef.current = new MediaRecorder(stream);

            mediaRecorderRef.current.ondataavailable = event => {
              chunksRef.current.push(event.data);
            };

            mediaRecorderRef.current.start();
          }
        } else {
          console.error(`No camera or audio input found with id ${id}`);
        }
      } catch (error) {
        
      }
    }
    const startRecording = async () => {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices();
        const cameras = devices.filter(device => device.kind === 'videoinput');
        const audioDevices = devices.filter(device => device.kind === 'audioinput');

        if (cameras.length > id && audioDevices.length > 0) {
          const stream = await navigator.mediaDevices.getUserMedia({
            video: { deviceId: cameras[id].deviceId },
            audio: {
              deviceId: audioDevices[0].deviceId,
              sampleRate: 44100,
              channelCount: 2,
            },
          });

          if (videoRef.current) {
            videoRef.current.srcObject = stream;

            mediaRecorderRef.current = new MediaRecorder(stream);

            mediaRecorderRef.current.ondataavailable = event => {
              chunksRef.current.push(event.data);
            };

            mediaRecorderRef.current.onstop = async () => {
              const blob = new Blob(chunksRef.current, { type: 'video/webm' });
              chunksRef.current = [];

              // download in local drive.
              // Using real-time local timestamp in the 'YYYY/MM/DD_HH:mm' format
              const currentDate = new Date();
              const timestamp =
                currentDate.getFullYear() +
                '_' +
                (currentDate.getMonth() + 1).toString().padStart(2, '0') +
                '_' +
                currentDate.getDate().toString().padStart(2, '0') +
                '_time_' +
                currentDate.getHours().toString().padStart(2, '0') +
                ':' +
                currentDate.getMinutes().toString().padStart(2, '0');

              const filename = `recorded-webcams_${timestamp}.webm`;

              // const url = URL.createObjectURL(blob);
              // const a = document.createElement('a');
              // a.href = url;

              // a.download = filename;
              // document.body.appendChild(a);
              // a.click();
              // document.body.removeChild(a);

              // upload to aws s3

              const formData = new FormData();
              formData.append('file', blob, filename);

              try {
                const response = await axios.post(`${SERVER_URL}/api/upload`, formData, {
                  headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${user.token}`
                  },
                });
                
                if (response.data && response.data.fileUrl) {
                  console.log(response.data.fileUrl);
                  onRecordingComplete(response.data.fileUrl, id); // Send recordingUrl and webcamId to parent component
                }
                console.log('File uploaded successfully');
              } catch (error) {
                console.error('Error uploading file:', error);
              }

            };

            mediaRecorderRef.current.start();
          }
        } else {
          console.error(`No camera or audio input found with id ${id}`);
        }
      } catch (error) {
        console.error('Error accessing camera or audio:', error);
      }
    };

    // start recording or preview here
    console.log(`Camera ${id} is recording from webacam component`, isRecording)
    if (isRecording) {
      startRecording();
    }else if (isPreview){
      startPreview();
    }

    return () => {
      if (mediaRecorderRef.current && mediaRecorderRef.current.state === 'recording') {
        mediaRecorderRef.current.stop();
      }
    };
     // eslint-disable-next-line
  }, [id, isRecording, isPreview, SERVER_URL, user.token]); 

  return <video ref={videoRef} autoPlay muted className='h-full w-full object-cover' />;
}

export default WebcamComponent;
