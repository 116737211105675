import React from "react";
import Onplane from "./Onplane";
import ToggleHistory from "./ToggleHistory";


function Step5 ({ sessionId }) {

  return (
    <div className="grid h-[500px] overflow-auto grid-cols-12 gap-5 w-full">
      <div className="flex flex-cols col-span-6">
        <Onplane sessionId={sessionId} />
      </div>
      <div className="flex flex-cols col-span-6">
        <ToggleHistory sessionId={sessionId} />
      </div>
      
    </div>
  );
};

export default Step5;
