import React from "react";
// Admin Imports
import Xrsimulation from "views/admin/xrsimulation";
import Clients from "views/admin/clients";

// Auth Imports
import SignIn from "views/auth/SignIn/SignIn";

// Icon Imports
import {
  MdOutlineFlightTakeoff,
  MdLogout,
  MdAssignmentInd,
  MdLogin
} from "react-icons/md";

const routes = [

  {
    name: "All Clients",
    layout: "/admin",
    icon: <MdAssignmentInd className="h-6 w-6" />,
    path: "clients",
    component: <Clients />,
  },

  {
    name: "donotshow",
    hide: true,
    layout: "/admin",
    path: "XRsimulation/session/:id",
    icon: <MdOutlineFlightTakeoff className="h-6 w-6" />,
    component: <Xrsimulation />,
   
  },
  {
    name: "donotshow",
    hide: true,
    layout: "/admin",
    path: "XRsimulation/sessioninfo/:id",
    icon: <MdOutlineFlightTakeoff className="h-6 w-6" />,
    component: <Xrsimulation />,
   
  },

  {
    name: "XR Simulation",
    layout: "/admin",
    path: "XRsimulation",
    icon: <MdOutlineFlightTakeoff className="h-6 w-6" />,
    component: <Xrsimulation />,
   
  },
  {
    name: "donotshow",
    layout: "/auth",
    path: "/sign-in",
    icon: <MdLogin className="h-6 w-6" />,
    component: SignIn,
    hide: true
  },

];

export const Logout = [
  {
    name: "Log Out",
    layout: "/auth",
    path: "/sign-out",
    icon: <MdLogout className="h-6 w-6"  />,
    component: SignIn,
  }
];

export default routes;
