import React, { useState, useEffect } from 'react';
import {
  columnsDataComplex,
} from "./variables/columnsData";

import EventHistory from "./components/EventHistory";
import { useParams } from 'react-router-dom';
import axios from 'axios'
import ClientProfile from './components/ClientProfile';
import RecordingsPreview from './components/RecordingsPreview';
import { useAuth } from 'context/auth-context';

const SessionInfo = () => {
  const [visibleComponent] = useState('A'); // Default to 'A'
  const [eventHistoryData, setEventHistoryData] = useState([]);
  const [clientProfile, setClientProfile] = useState([]);
  const [userData, setUserData] = useState(null);
  const [videoUrls, setVideoUrls] = useState([]);
  const params = useParams();
  let sessionId = params.id;
  const SERVER_URL = process.env.REACT_APP_API_BASE_URL;
  const { user } = useAuth();

  useEffect(() => {
    const getData = async () => {
      await axios
        .get(`${SERVER_URL}/api/session/${sessionId}`, {
          headers: {
            'Authorization': `Bearer ${user.token}`
          }
        })
        .then((res) => {
          setEventHistoryData(res.data.data.eventHistory);
          // console.log(res.data.data)
          setVideoUrls([res.data.data.RecordingURL1, res.data.data.RecordingURL2, res.data.data.RecordingURL3])
          setClientProfile(res.data.data.client)
          setUserData(res.data.data.user);
        })
        .catch((err) => {
          console.log(err.message);
        });
    };

    getData();
  }, [SERVER_URL, sessionId, user.token]);

  return (
    <div>

      {/* Event List and Client profile */}
      <div className="grid h-[720px] flex-row grid-cols-12 gap-5 w-full">
        {visibleComponent === 'A' && <EventHistory columnsData={columnsDataComplex} tableData={eventHistoryData} />}
        {visibleComponent === 'A' && <ClientProfile clientProfile={clientProfile} user={userData} />} 
      </div>

      {/* Recordings */}
      <RecordingsPreview videoUrls={videoUrls} />
    </div>
  );
};

export default SessionInfo;
