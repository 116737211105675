import Card from "components/card";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { useMemo } from "react";
import { FiSearch } from "react-icons/fi";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";

const EventHistory = (props) => {
  const { columnsData, tableData } = props;
  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    initialState: { pageIndex },
  } = tableInstance;

  const dateFormatter = (date) => {
      const options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit'};
      const formatted = (new Date(date)).toLocaleDateString('en-US', options);
      const date_parts = formatted.substring(0, formatted.indexOf(",")).split(" ").reverse().join(" ");
      const formatted_date = date_parts + formatted.substr(formatted.indexOf(",") + 1);
      return formatted_date;
  };

  return (
    <Card
      extra={
        "w-full h-full flex flex-cols col-span-7 p-5 sm:overflow-x-auto bg-gray-800/30 backdrop-blur-xl"
      }
    >
      <div className="relative flex items-center justify-between">
        <div className="text-xl font-bold text-navy-700 dark:text-white">
          Event History
        </div>
        {/* <CardMenu/> */}
      </div>

      <div className="mt-5 flex h-[50px] items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white xl:w-[200px]">
        <p className="pl-3 pr-2 text-xl">
          <FiSearch className="h-4 w-4 text-gray-400 dark:text-white" />
        </p>
        <input
          type="text"
          placeholder="Search..."
          className="block h-full w-full rounded-full bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit"
        />
      </div>
      <div className="mt-4 h-full overflow-x-scroll xl:overflow-hidden">
        <table {...getTableProps()} className="w-full">
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    key={index}
                    className="auto-cols-max grid-flow-col border-b border-gray-200 pr-5 pb-[10px] text-start dark:!border-navy-700"
                  >
                    <p className="text-xs tracking-wide text-gray-800 dark:text-white">
                      {column.render("Header")}
                    </p>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={index}>
                  {row.cells.map((cell, index) => {
                    let data = "";
                    if (cell.column.Header === "ID") {
                      data = (
                        <div className="flex items-center gap-2">
                          <p className="text-sm font-bold text-navy-700 dark:text-white">
                            {cell.value}
                          </p>
                        </div>
                      );
                    } else if (cell.column.Header === "EVENT NAME") {
                      data = (
                        <div className="flex items-center gap-2">
                          <p className="text-sm font-bold text-navy-700 dark:text-white">
                            {cell.value}
                          </p>
                        </div>
                      );
                    } else if (cell.column.Header === "STATUS") {
                      data = (
                        <p className="text-sm font-bold text-navy-700 dark:text-white">
                          {cell.value}
                        </p>
                      );
                    } else if (cell.column.Header === "CREATED AT") {
                      data = (
                        <p className="text-sm font-bold text-navy-700 dark:text-white">
                          {dateFormatter(cell.value)}
                        </p>
                      );
                    } else if (cell.column.Header === "COMMENT") {
                      data = (
                        <p className="text-sm font-bold text-navy-700 dark:text-white">
                          {cell.value === "" || cell.value === null
                            ? "___"
                            : cell.value}
                        </p>
                      );
                    }

                    return (
                      <td
                        className="pt-[14px] pb-[18px] sm:text-[14px]"
                        {...cell.getCellProps()}
                        key={index}
                      >
                        {data}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="flex justify-end gap-1 sm:flex sm:items-end sm:justify-end">
        {/* pagination wrapper */}
        <div className="flex items-end justify-end gap-x-1">
          <button
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
            className="inline-flex min-h-[38px] min-w-[38px] items-center justify-center gap-x-1.5 rounded-lg py-2 px-2.5 text-sm text-gray-800 hover:bg-gray-100 focus:bg-gray-100 focus:outline-none disabled:pointer-events-none disabled:opacity-50 dark:text-white dark:hover:bg-white/10 dark:focus:bg-white/10"
          >
            <MdChevronLeft className="text-sm" />
            <span>Previous</span>
          </button>
          <div className="flex items-center gap-x-1">
            {(() => {
              const buttons = [];
              if (pageCount < 3) {
                for (let index = 0; index < pageOptions.length; index++) {
                  buttons.push(
                    <button
                      key={index}
                      className="flex min-h-[38px] min-w-[38px] items-center justify-center rounded-lg py-2 px-3 text-sm text-gray-800 hover:bg-gray-100 focus:bg-gray-100 focus:outline-none disabled:pointer-events-none disabled:opacity-50 dark:text-white dark:hover:bg-white/10 dark:focus:bg-white/10"
                      aria-current="page"
                      onClick={() => gotoPage(index)}
                    >
                      {index + 1}
                    </button>
                  );
                }
                return buttons;
              } else {
                for (let index = 0; index < 3; index++) {
                  buttons.push(
                    <button
                      type="button"
                      key={index}
                      className="flex min-h-[38px] min-w-[38px] items-center justify-center rounded-lg py-2 px-3 text-sm text-gray-800 hover:bg-gray-100 focus:bg-gray-100 focus:outline-none disabled:pointer-events-none disabled:opacity-50 dark:text-white dark:hover:bg-white/10 dark:focus:bg-white/10"
                      aria-current="page"
                      onClick={() => gotoPage(index)}
                    >
                      {index + 1}
                    </button>
                  );
                }
                return buttons;
              }
            })()}

            {pageCount > 3
              ? () => {
                  // show next 3 pages
                  <div className="hs-tooltip inline-block">
                    <button
                      onClick={() => gotoPage(pageIndex + 3)}
                      className="hs-tooltip-toggle group flex min-h-[38px] min-w-[38px] items-center justify-center rounded-lg p-2 text-sm text-gray-400 hover:text-blue-600 focus:bg-gray-100 focus:outline-none disabled:pointer-events-none disabled:opacity-50 dark:text-gray-500 dark:hover:text-blue-500 dark:focus:bg-white/10"
                    >
                      <span className="text-xs group-hover:hidden">•••</span>
                      <svg
                        className="hidden h-5 w-5 flex-shrink-0 group-hover:block"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path d="m6 17 5-5-5-5" />
                        <path d="m13 17 5-5-5-5" />
                      </svg>
                      <span
                        className="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible dark:bg-slate-700 invisible absolute z-10 inline-block rounded bg-gray-900 py-1 px-2 text-xs font-medium text-white opacity-0 shadow-sm transition-opacity"
                        role="tooltip"
                      >
                        Next 3 pages
                      </span>
                    </button>
                    {/* show last page button */}
                    <button
                      onClick={() => gotoPage(pageCount - 1)}
                      className="flex min-h-[38px] min-w-[38px] items-center justify-center rounded-lg py-2 px-3 text-sm text-gray-800 hover:bg-gray-100 focus:bg-gray-100 focus:outline-none disabled:pointer-events-none disabled:opacity-50 dark:text-white dark:hover:bg-white/10 dark:focus:bg-white/10"
                    >
                      {pageOptions.length}
                    </button>
                  </div>;
                }
              : null}
          </div>
          <button
            onClick={() => nextPage()}
            disabled={!canNextPage}
            className="inline-flex min-h-[38px] min-w-[38px] items-center justify-center gap-x-1.5 rounded-lg py-2 px-2.5 text-sm text-gray-800 hover:bg-gray-100 focus:bg-gray-100 focus:outline-none disabled:pointer-events-none disabled:opacity-50 dark:text-white dark:hover:bg-white/10 dark:focus:bg-white/10"
          >
            <span>Next</span>
            <MdChevronRight className="text-sm" />
          </button>
        </div>
        {/* page size dopdown */}

        <div className="flex w-32 flex-col justify-end gap-6">
          <select
            className="inline-flex w-32 items-center gap-x-1 rounded-lg border border-gray-200 py-1.5 px-2 text-sm text-gray-800 shadow-sm hover:bg-gray-50 focus:bg-gray-100 focus:outline-none disabled:pointer-events-none disabled:opacity-50 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 dark:focus:bg-gray-700"
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[10, 20, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </div>
        {/* End Dropdown */}
      </div>
    </Card>
  );
};

export default EventHistory;
