export const columnsSessionsList = [
  {
    Header: "ID",
    accessor: (_row, i) => i + 1 ,
  },
  {
    Header: "Started",
    accessor: "startedAt",
  },
  {
    Header: "Ended",
    accessor: "endedAt",
  },
  {
    Header: "Client",
    accessor: "client.name",
  },
  {
    Header: "Administred by",
    accessor: "user.username",
  },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "Action",
    accessor: "id",
  },
];