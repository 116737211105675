import Card from "components/card";
import React from "react";
import Cameraone from "./Cameraone";
import Cameraonesecond from "./Cameraonesecond";
import Camerathird from "./Camerathird";

function Step3 () {

  return (
    <Card extra={"w-full h-[500px] overflow-auto p-3 "}>
      {/* Header */}
      <div className="mt-2 mb-8 w-full ">
        <h4 className="px-2 text-xl font-bold text-navy-700 dark:text-white">
          Camera Preview
        </h4>
        <p className="mt-2 px-2 text-base text-gray-800 dark:text-white">
          In the XR simulation room, individuals with autism engage in immersive
          therapeutic experiences, fostering a supportive environment for
          sensory exploration and social interaction
        </p>
      </div>

      {/* Preview camera streaming */}

      <div className="mt-6 flex h-fit w-full flex-col gap-5 lg:grid lg:grid-cols-3">
        <Cameraone />
        <Cameraonesecond />
        <Camerathird />
      </div> 

     
    </Card>
  );
};

export default Step3;
